
import { defineComponent, ref, onMounted } from "vue";
import { API_URL } from "@/common/config";
import { useStore } from "vuex";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "Login",
  components: {},
  setup() {
    const email = ref("");
    const password = ref("");
    const error = ref(false);
    const buttonText = ref("Login");
    const isButtonDisable = ref(false);

    const store = useStore();
    const router = useRouter();

    const load = () => {
      store.dispatch("auth/logout");
    };

    onMounted(load);

    const login = () => {
      error.value = false;
      buttonText.value = "Wait...";
      isButtonDisable.value = true;
      store
        .dispatch("auth/login", {
          email: email.value,
          password: password.value,
        })
        .then(
          () => {
            router.push({ name: "Home" });
          },
          () => {
            error.value = true;
            buttonText.value = "Login";
            isButtonDisable.value = false;
          }
        );
    };

    const getCookie = (name: string) => {
      const matches = document.cookie.match(
        new RegExp(
          `(?:^|; )${name.replace(
            // eslint-disable-next-line no-useless-escape
            /([\.$?*|{}\(\)\[\]\\\/\+^])/g,
            "\\$1"
          )}=([^;]*)`
        )
      );
      return matches ? decodeURIComponent(matches[1]) : null;
    };

    const loginWith = (value: string) => {
      const ref = getCookie("link");
      window.open(`${API_URL}/redirect/${value}?link=${ref}`, "_self");
    };

    return {
      loginWith,
      login,
      email,
      password,
      error,
      buttonText,
      isButtonDisable,
    };
  },
});
